<template>
    <v-container v-if="$store.state.user">

        <!-- Page Title-->
        <v-row class="text-center mt-10">
            <v-col class="mb-2">
                <h3 class="display-2 font-weight-bold mb-3">
                    Add A New Association
                </h3>
            </v-col>
        </v-row>

        <!-- Start of form -->
        <v-row class="justify-center">
            <v-col class="mt-16" cols="4">
                <v-form ref="form" v-model="valid">

                    <!-- Name input field -->
                    <v-text-field v-model="name" :rules="nameRules" label="Name"></v-text-field>

                    <!-- Interest input field (Note the custom rule assigned) -->
                    <v-text-field v-model="interest" :rules="interestRules" label="Interest"></v-text-field>

                    <!-- Notes input field -->
                    <v-textarea v-model="notes" label="Notes"></v-textarea>

                    <!-- Form submit button -->
                    <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
                        Submit
                    </v-btn>

                    <!-- Form clear button (removes current user input) -->
                    <v-btn color="error" class="mr-4" @click="reset">Clear Form</v-btn>

                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import AssociationsService from '../../services/AssociationsService'
    export default {
        name: 'addNewAssociation',

        data() {
            return {
                valid: true,
                name: '',
                interest: '',
                notes: '',

                // Custom rules to ensure valid user input
                nameRules: [
                        v => !!v || 'Name is required',
                        v => (v && v.length <= 30) || 'Name must be less than 30 characters',
                ],
                interestRules: [
                        v => !!v || 'Interest is required',
                        v => /[0-9]*\.[0-9]{2,2}$/.test(v) || 'Must be in the form of a two-digit decimal.',
                ]
            }
        },

        methods: {
            validate () {
                this.$refs.form.validate()

                try {
                    AssociationsService.addNewAssociation({
                        name: this.name,
                        interest: parseFloat(this.interest),
                        notes: this.notes,
                        archived: false
                    })
                } catch (error) {
                    this.error = error.response.data.error
                }

                this.$router.push({ path: '/associations/associations'})

            },

            // Reset all current user inputs (thereby resetting the form)
            reset () {
                this.$refs.form.reset()
            },
        },
    }
</script>